<template>
    <v-col
        cols="12"
        sm="8"
        md="6"
        offset-md="2"
    >
        <base-card>
            <v-list-item three-line>
                <v-list-item-content>
                    <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                    <div class="text-body-2 mb-0 text--disabled">
                        {{ $t('admin.dashboard.resumeUsersComponnent.usersParticipatedTitle') }}
                    </div>

                    <v-list-item-title
                        class="text-h5 mb-1 font-weight-bold"
                    >
                        <span> {{ $t('admin.dashboard.resumeUsersComponnent.usersParticipated', {count: purposesCloud.usersCountRecognized, total: purposesCloud.usersCount}) }}</span>
                    </v-list-item-title>

                    <!--                  madeRecognitionToday-->
                    <v-list-item-subtitle class="mt-1">
                        <div class="d-flex flex-wrap">
                            <v-sheet
                                height="20"
                                width="20"
                                class="info lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1"
                            >
                                <v-icon
                                    class="info--text text-caption"
                                >
                                    mdi-arrow-top-right
                                </v-icon>
                            </v-sheet>
                            <span
                                class="font-weight-bold text-primary"
                            >
                                {{ $t('admin.dashboard.resumeUsersComponnent.wasRecognizedToday', {count: purposesCloud.usersCountRecognizedToday, total:purposesCloud.usersCountRecognized, percent: numberHelper.percent(purposesCloud.usersCountRecognizedToday, purposesCloud.usersCountRecognized)}) }}
                            </span>
                        </div>
                    </v-list-item-subtitle>

                    <!--                  madeRecognitionToday-->
                    <v-list-item-subtitle class="mt-2">
                        <div class="d-flex flex-wrap">
                            <v-sheet
                                height="20"
                                width="20"
                                class="info lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1"
                            >
                                <v-icon
                                    class="info--text text-caption"
                                >
                                    mdi-arrow-top-right
                                </v-icon>
                            </v-sheet>
                            <span
                                class="font-weight-bold text-primary"
                            >
                                {{ $t('admin.dashboard.resumeUsersComponnent.madeRecognitionToday', {count: purposesCloud.usersCountMadeRecognitionToday, total:purposesCloud.usersCountMadeRecognition, percent: numberHelper.percent(purposesCloud.usersCountMadeRecognitionToday, purposesCloud.usersCountMadeRecognition)}) }}

                            </span>
                        </div>
                    </v-list-item-subtitle>
                </v-list-item-content>

                <apexchart
                    width="100"
                    height="150"
                    type="radialBar"
                    :options="jmPieChartOne.chartOptions"
                    :series="jmPieChartOne.series"
                />
            </v-list-item>
        </base-card>
    </v-col>
</template>

<script>
import axios from '@/plugins/axios'

export default {
    name: 'UsersRecognitionsResume',
    data: function () {
        return {
            purposesCloud: {
                usersCountMadeRecognition: 0,
                usersCountMadeRecognitionToday: 0,
                usersCountRecognized: 0,
                usersCountRecognizedToday: 0,
                usersCount: 0
            },
            jmPieChartOne: {
                series: [0],
                chartOptions: {
                    chart: {
                        height: 150,
                        type: "radialBar"
                    },

                    colors: ["#006ff5"],
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 0,
                                size: "60%"
                            },

                            dataLabels: {
                                show: true,
                                name: {
                                    offsetY: 0,
                                    show: false,
                                    color: "#888",
                                    fontSize: "13px"
                                },
                                value: {
                                    offsetY: 10,
                                    color: "#828d99",
                                    fontSize: "20px",
                                    show: true,
                                    fontWeight: 700
                                }
                            }
                        }
                    },

                    stroke: {
                        lineCap: "round"
                    },
                    labels: ["Progress"]
                }
            }
        }
    },
    created() {
        this.fetchPurposesCloudPublished();
    },
    methods: {
        async fetchPurposesCloudPublished() {
            try {
                const purposesCloudPublished = await axios
                    .get('dashboard/usersRecognition');

                this.purposesCloud = purposesCloudPublished.data;
                this.jmPieChartOne.series = [this.numberHelper.percent(purposesCloudPublished.data.usersCountRecognized, purposesCloudPublished.data.usersCount, 1)];
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
