<template>
    <v-col cols="12">
        <base-card>
            <v-card-title>{{ $t('admin.dashboard.historyComponnent.title') }}</v-card-title>
            <v-card-text>
                <apexchart
                    v-if="loaded"
                    type="area"
                    height="300"
                    :options="marketChart.chartOptions"
                    :series="marketChart.series"
                />
            </v-card-text>
        </base-card>
    </v-col>
</template>

<script>
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'

export default {
    name: 'PurposesCloudResume',
    data: function () {
        return {
            loaded: false,
            marketChart: {
                series: [],
                chartOptions: {
                    colors: ['#83CFFF', '#2D99FF'],
                    fill: {
                        type: 'solid'

                    },
                    legend: {
                        show: true
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: [],
                        labels: {
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: 'MMM / yy',
                                day: 'dd MMM',
                                hour: 'HH:mm'
                            }
                        }
                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yy'
                        },
                        y: {
                            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                                return value + ''
                            }
                        }
                    },
                    chart: {
                        height: 300,
                        type: 'area',
                        toolbar: {
                            show: false
                        },
                        defaultLocale: 'es',
                        locales: [{
                            name: 'es',
                            options: {
                                months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                                shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                                days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                                shortDays: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']
                            }
                        }, {
                            name: "en",
                            options: {
                                months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                                shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                                days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                                shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
                            }
                        }]
                    }
                }
            }
        }
    },
    created() {
        this.fetchPurposesCloudHistory();
    },
    computed: {
        ...mapGetters("languages", ['getLang'])
    },
    methods: {
        async fetchPurposesCloudHistory() {
            try {
                const purposes = await axios
                    .get('dashboard/lastPurposesByDay');

                // fulfill map with the data loaded
                const purposesCloudByDay = purposes.data.purposesCloudByDay;
                const mapPurposesCloud = {};
                for (const data of purposesCloudByDay) {
                    mapPurposesCloud[data.date] = data.count;
                }

                const purposesDraftsByDay = purposes.data.purposesDraftsByDay;
                const mapPurposesDrafts = {};
                for (const data of purposesDraftsByDay) {
                    mapPurposesDrafts[data.date] = data.count;
                }

                // creating data for charts

                const serieCloud = {
                    name: this.$t("admin.dashboard.historyComponnent.serieB"),
                    data: new Array(31)
                };

                const serieDrafts = {
                    name: this.$t('admin.dashboard.historyComponnent.serieA'),
                    data: new Array(31)
                };

                const categories = new Array(31);

                var today = new Date();
                var priorDate = new Date();
                priorDate.setDate(today.getDate() - 31);

                for (let i = 0; priorDate <= today; priorDate.setDate(priorDate.getDate() + 1), i++) {
                    const date = this.dateHelper.dateToString(priorDate);

                    const valueCloud = mapPurposesCloud[date] ?? 0;
                    serieCloud.data[i] = valueCloud;

                    const valueDrafts = mapPurposesDrafts[date] ?? 0;
                    serieDrafts.data[i] = valueDrafts;

                    // const [year, month, day] = date.split('-')
                    // const dateFormat = `${day}/${month}`;
                    categories[i] = date;
                }

                this.marketChart.series[0] = serieDrafts;
                this.marketChart.series[1] = serieCloud;

                this.marketChart.chartOptions.xaxis.categories = categories;
                this.marketChart.chartOptions.chart.defaultLocale = this.getLang ?? 'es';

                this.loaded = true;
                // this.jmPieChartOne.series = [this.numberHelper.percent(purposesCloudPublished.data.count, purposesCloudPublished.data.usersCount, 0)];
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
