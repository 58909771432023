<template>
    <v-col
        cols="12"
        sm="6"
        md="4"
    >
        <base-card>
            <v-list-item three-line>
                <v-list-item-content>
                    <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                    <div class="text-body-2 mb-0 text--disabled">
                        {{ $t('admin.dashboard.resumeCreatedComponnent.recognitionsCountTitle') }}
                    </div>
                    <v-list-item-title
                        class="text-h5 mb-1 font-weight-bold"
                    >
                        <span> {{ $t('admin.dashboard.resumeCreatedComponnent.recognitionsCount', {count: purposesCloud.recognitionsCountToday, total: purposesCloud.recognitionsCount}) }}</span>
                    </v-list-item-title>
                </v-list-item-content>

                <apexchart
                    width="100"
                    height="150"
                    type="radialBar"
                    :options="jmPieChartOne.chartOptions"
                    :series="jmPieChartOne.series"
                />
            </v-list-item>
        </base-card>
    </v-col>
</template>

<script>
import axios from '@/plugins/axios'

export default {
    name: 'PurposesCloudResume',
    data: function () {
        return {
            purposesCloud: { recognitionsCountToday: 0, recognitionsCount: 0 },
            jmPieChartOne: {
                series: [0],
                chartOptions: {
                    chart: {
                        height: 150,
                        type: "radialBar"
                    },

                    colors: ["#006ff5"],
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 0,
                                size: "60%"
                            },

                            dataLabels: {
                                show: true,
                                name: {
                                    offsetY: 0,
                                    show: false,
                                    color: "#888",
                                    fontSize: "13px"
                                },
                                value: {
                                    offsetY: 10,
                                    color: "#828d99",
                                    fontSize: "20px",
                                    show: true,
                                    fontWeight: 700
                                }
                            }
                        }
                    },

                    stroke: {
                        lineCap: "round"
                    },
                    labels: ["Progress"]
                }
            }
        }
    },
    created() {
        this.fetchPurposesCloudPublished();
    },
    methods: {
        async fetchPurposesCloudPublished() {
            try {
                const purposesCloudPublished = await axios
                    .get('dashboard/recognitionMade');

                this.purposesCloud = purposesCloudPublished.data;
                this.jmPieChartOne.series = [this.numberHelper.percent(purposesCloudPublished.data.recognitionsCountToday, purposesCloudPublished.data.recognitionsCount, 1)];
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
